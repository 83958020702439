import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/* Constants */
import { callAPIConstants } from '../constants/callAPI-constants';
import { localStorageConstants } from '../constants/localStorage-constants';
/* Services */
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {
  callAPIConstants = callAPIConstants;
  localStorageConstants = localStorageConstants
  public accessPermissions: BehaviorSubject<any>
  constructor(private commonService: CommonService, private localStorageService: LocalStorageService, public logoutService: LogoutService) {
    if (this.localStorageService.getToken(this.localStorageConstants.Permissions) !== 'undefined') {
      this.accessPermissions = new BehaviorSubject<any>(this.localStorageService.getToken(this.localStorageConstants.Permissions));
    } else {
      this.logoutService.logout();
      this.localStorageService.removeToken(this.localStorageConstants.Permissions);
      this.commonService.router.navigate(['login']);
    }
  }

  /***************************************************************************
  @PURPOSE      : Get Permission
  @PARAMETERS   : N/A
  @RETURN       : Permission Observable
  ****************************************************************************/
  getPermissionData(): Observable<any> {
    return this.accessPermissions.asObservable();
  }
  /***************************************************************************
  @PURPOSE      : Set Permission
  @PARAMETERS   : details
  @RETURN       : N/A
  ****************************************************************************/
  setPermissionData(details) {
    this.localStorageService.setToken(this.localStorageConstants.Permissions, details);
    this.accessPermissions = new BehaviorSubject<any>((details));
  }

  /***************************************************************************
   @PURPOSE      : Get Permissions List of Logged in User
   @PARAMETERS   : N/A
   @RETURN       : N/A
   ****************************************************************************/
  getPermissions() {
    this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.setPermissionData(success.data);
      }
    });
  }
  /***************************************************************************
  @PURPOSE      : Get Page wise Permissions
  @PARAMETERS   : page
  @RETURN       : access object
  ****************************************************************************/
  getPagePermission(page): Object {
    let pageRes: any = {}
    this.getPermissionData().subscribe(res => pageRes = res);

    if(pageRes[page]){
      switch (page) {
        case 'dashboard':
          return pageRes.dashboard;
        case 'my_account':
          pageRes.my_account.maintenance_view = pageRes?.maintenance_mode?.view || 0;
          pageRes.my_account.maintenance_edit = pageRes?.maintenance_mode?.edit || 0;
          return pageRes.my_account;
        case 'courses':
          return pageRes.courses;
        case 'industry':
          return pageRes.industry;
        case 'course_requests':
          return pageRes.course_requests;
        case 'users':
          pageRes.users.operator_view = pageRes?.manage_operators?.view || 0;
          return pageRes.users;
        case 'roles':
          return pageRes.roles;
        case 'manage_operators':
          return pageRes.manage_operators;
        case 'manage_subadmins':
          pageRes.manage_subadmins.roles_view = pageRes?.roles?.view || 0;
          return pageRes.manage_subadmins;
        case 'products':
          return pageRes.products;
        case 'product_type':
          return pageRes.product_type;
        case 'resources':
          return pageRes.resources;
        case 'news':
          return pageRes.news;
        case 'manage_push_notification':
          return pageRes.manage_push_notification;
        case 'manage_email_templates':
          return pageRes.manage_email_templates;
        case 'manage_push_notification_templates':
          return pageRes.manage_push_notification_templates;
      }
    } else {
      return pageRes[page] = {
        add: 0,
        edit: 0,
        view: 0,
        delete: 0
      }
    }
    
  }


  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : N/A
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    tempData[index].isVisible = false;
    return tempData;
  }
}
