export const messages = {
    ERROR: {
        REQUIRED: {
            email: 'Enter Email Address',
            Password: 'Enter Password',
            code: 'Enter Code',
            confirmPassword: 'Enter Confirm Password',
            newPassword: 'Enter New Password',
            oldPassword: 'Enter Old Password',
            domain_name: 'Enter Domain Name',
            /* Email Templates */
            emailKey: 'Enter Title',
            subject: 'Enter Subject',
            /* Push notification Templates */
            message: "Enter Message",

            /* Team Member */
            teamName: 'Enter Name',
            role: 'Select Role',
            roleName: 'Enter Role',

            /* Courses */
            courseDate: 'Select Course Date',
            displayOrder: 'Select Vendor',
            /* Account */
            contact_no: 'Enter Contact No',
            website: 'Enter Website',
            address: 'Enter Address',
            latitude: 'Enter Latitude',
            longitude: 'Enter Longitude',
            unique_id_name: 'Enter Unique ID Name',

            /* Course err keys */
            title: 'Enter Course Title',
            description: 'Enter Description',
            display_order: 'Enter Display Order',
            keywords: 'Enter Keywords',
            schedule_at: 'Pick Date & Time',
            moduleTitle: 'Enter Module Title',

            /* Master Data */
            industry: 'Enter Industry',
            ProductType: 'Enter Product Type',
            cancellation_reason: 'Enter Cancellation Reason',

            Email: 'Enter Email',
            Username: 'Enter Username',
            currentPassword: 'Enter Current Password',
            userName: 'Enter User Name',
            companyName: 'Enter Company Name',
            Amount: ' Enter Amount',
            filterName: 'Enter Name of Filter',
            fromEmailId: 'Please enter email',
            emailTemplateId: 'Select Event',
            faqsCategory: 'Enter Category',
            answer: 'Enter Answer',
            question: 'Enter Question',

            blogTitle: 'Enter Blog Title',
            slug: 'Enter Slug',
            defaultFromEmail: 'Enter Default From EMail',
            defaultAdminEmail: 'Enter Default Admin Email',
            metaTitle: 'Enter Meta Title',
            metaDescription: 'Enter Meta Description',
            metaKeyword: 'Enter Meta Keyword',
            pageTitle: 'Enter Page Title',
            currency: 'Select Currency',
            mainCurrency: 'Enter Currency',
            dateFormat: 'Select Date Format',
            templateName: 'Enter Template Name',
            countryName: 'Enter Country Name',
            countryId: 'Enter Country Name',
            countryCode: 'Enter Country Code',
            phoneCode: 'Enter Phone Code',
            timezone: 'Enter Timezone',
            dob: 'Select Date Of Birth',
            country: 'Select Country',
            languages: 'Select language',
            
            /*Maintenance */
            mobile_message: 'Enter Mobile Message',
            mobileTitle: 'Enter Mobile Title',
            email_message: 'Enter Email Message',
            emailTitle: 'Enter Email Title',
        },
        PATTERN: {
            Email: 'Enter Valid Email',
            Password: 'Password must contain 8-20 digits with at least 1 uppercase, 1 lowercase and 1 special character',
            Contactno: 'Invalid Contact Number',
            newPassword: 'Password must contain 8-20 digits with at least 1 uppercase, 1 lowercase and 1 special character',
            link: 'url should contain https://',
            latitude: "Please enter valid latitude",
            longitude: "Please enter valid longitude",
            website: "Please enter valid website"
        },
        MINLENGTH: {
            contact_no: 'Contact number should be upto between 10 to 15 digits',
            code: 'Confirmation Code must contain 6 digits',
            answer: 'Answer must be minimum 20 characters.',
            question: 'Question must be minimum 10 characters.',
            description: 'Blog description should be minimum 20 characters',
            category: 'Blog category should be minimum 2 characters',
            mobile: 'Mobile Number should b e 10 digits',
            PhoneNo: 'Mobile Number should be 10 digits',
            confirmPassword: 'Require at least 8 characters',
            newPassword: 'Require at least 8 characters',
            port: 'Minimum 3 didgit required',
        },
        CUSTOM: {
            ConfirmPassword: 'Confirm password does not match!',
        },
    },
};
