import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
/* Auth Guard */
import { CanPublicActivate, CanAuthActivate, CanViewActivate } from "./common/TS-files/auth.guard";
/* Components */
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { TrainingCoursesComponent } from "./modules/training-courses/training-courses.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
/* plugin */
import { NgOtpInputModule } from 'ng-otp-input';
import { RecaptchaModule } from "ng-recaptcha";
/* Shared */
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponent } from "./modules/page-not-found/page-not-found.component";
import { MaintenanceComponent } from "./modules/maintenance/maintenance.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [CanPublicActivate],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    canActivate: [CanPublicActivate],
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "forgot-password",
    canActivate: [CanPublicActivate],
    component: ForgotPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "verify-code",
    canActivate: [CanPublicActivate],
    component: ConfirmEmailComponent,
    pathMatch: "full",
  },
  {
    path: "maintenance-mode",
    component: MaintenanceComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: 'manage-subadmins',
        loadChildren: () => import('./modules/manage-subadmins/manage-subadmins.module')
          .then((m) => m.ManageSubadminsModule),
      },
      {
        path: "training-courses",
        data: { page: 'courses' },
        canActivate: [CanAuthActivate, CanViewActivate],
        component: TrainingCoursesComponent,
      },
      {
        path: 'manage-roles',
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./modules/analytics/analytics.module')
          .then((m) => m.AnalyticsModule),
      },
      {
        path: "user-management",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/user-management.module").then(
            (mod) => mod.UserManagementModule
          ),
      },
      {
        path: "products",
        data: { page: 'products' },
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/products/products.module").then(
            (mod) => mod.ProductsModule
          ),
      },
      {
        path: "resources",
        data: { page: 'resources' },
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/resources/resources.module").then(
            (mod) => mod.ResourcesModule
          ),
      },
      {
        path: "news",
        data: { page: 'news' },
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/news/news.module").then(
            (mod) => mod.NewsModule
          ),
      },
      {
        path: "orders",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/orders/orders.module").then(
            (mod) => mod.OrdersModule
          ),
      },
      {
        path: "reports",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/reports/reports.module").then(
            (mod) => mod.ReportsModule
          ),
      },
      {
        path: "contactUs-Inquiries",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/contact-us-inquiries/contact-us-inquiries.module").then(
            (mod) => mod.ContactUsInquiriesModule
          ),
      },
      {
        path: "training-course-requests",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/training-courses-request/training-courses-request.module").then(
            (mod) => mod.TrainingCoursesRequestModule
          ),
      },
      {
        path: "master",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/master-data/master-data.module").then(
            (mod) => mod.MasterDataModule
          ),
      },
      {
        path: "coupon-codes",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/coupen-codes/coupen-codes.module").then(
            (mod) => mod.CoupenCodesModule
          ),
      },
      {
        path: "cms",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/cms/cms.module").then(
            (mod) => mod.CmsModule
          ),
      },
      {
        path: 'email-templates',
        canActivate: [CanAuthActivate],
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      {
        path: "push-notification-templates",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/push-notification-templates/push-notification-templates.module").then(
            (mod) => mod.PushNotificationTemplatesModule
          ),
      },
      {
        path: "manage-push-notifications",
        data: { page: 'manage_push_notification' },
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/push-notification/push-notification.module").then(
            (mod) => mod.PushNotificationModule
          ),
      },
      {
        path: "manage-subscription",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/manage-subscription-plans/manage-subscription-plans.module").then(
            (mod) => mod.ManageSubscriptionPlansModule
          ),
      },
      {
        path: "payout",
        canActivate: [CanAuthActivate, CanViewActivate],
        loadChildren: () =>
          import("./modules/payout/payout.module").then(
            (mod) => mod.PayoutModule
          ),
      },
      {
        path: "my-account",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/my-account/my-account.module").then(
            (mod) => mod.MyAccountModule
          ),
      },
      {
        path: "page-not-found",
        component: PageNotFoundComponent,
        pathMatch: "full",
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

];

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    MainComponent,
    TrainingCoursesComponent,
    ForgotPasswordComponent,
    ConfirmEmailComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    RecaptchaModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanPublicActivate, CanAuthActivate, CanViewActivate],
})
export class AppRoutingModule { }
