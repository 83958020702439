import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from '../constants/errorMessagesData';


@Injectable()
export class ErrorMessages {
  public MSG = (messages as any);


  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case 'email': { message = required.email; } break;
          case 'Email': { message = required.Email; } break;
          case 'password': { message = required.Password; } break;
          case 'code': { message = required.code; } break;
          case 'confirmPassword': { message = required.confirmPassword; } break;
          case 'newPassword': { message = required.newPassword; } break;
          case 'oldPassword': { message = required.oldPassword; } break;
          case 'domain_name': { message = required.domain_name } break;
          /* Email Templates */
          case 'emailKey': { message = required.emailKey; } break;
          case 'subject': { message = required.subject; } break;
          /*Team Member*/
          case 'teamName': { message = required.teamName; } break;
          case 'role': { message = required.role; } break;
          case 'roleName': { message = required.roleName; } break;
          /*Courses*/
          case 'courseDate': { message = required.courseDate; } break;
          case 'displayOrder': { message = required.displayOrder; } break;
          /*Push notification Templates*/
          case 'message': { message = required.message; } break;
          /* Course err keys */
          case 'title': { message = required.title } break;
          case 'description': { message = required.description } break;
          case 'display_order': { message = required.display_order } break;
          case 'keywords': { message = required.keywords } break;
          case 'schedule_at': { message = required.schedule_at } break;
          case 'moduleTitle': { message = required.moduleTitle } break;
          /*  My Account err keys */
          case 'contact_no': { message = required.contact_no; } break;
          case 'website': { message = required.website; } break;
          case 'latitude': { message = required.latitude; } break;
          case 'longitude': { message = required.longitude; } break;
          case 'unique_id_name': { message = required.unique_id_name; } break;
          case 'address': { message = required.address; } break;
          /* Master Data err keys */
          case 'industry': { message = required.industry; } break;
          case 'ProductType': { message = required.ProductType; } break;
          case 'cancellation_reason': { message = required.cancellation_reason; } break;


          case 'userName': { message = required.userName; } break;
          case 'amount': { message = required.Amount; } break;
          case 'fromEmailId': { message = required.fromEmailId; } break;
          case 'faqsCategory': { message = required.faqsCategory } break;
          case 'answer': { message = required.answer } break;
          case 'question': { message = required.question } break;
          case 'blogTitle': { message = required.blogTitle } break;
          case 'slug': { message = required.slug } break;
          case 'emailTemplateId': { message = required.emailTemplateId; } break;
          case 'metaTitle': { message = required.metaTitle; } break;
          case 'metaDescription': { message = required.metaDescription; } break;
          case 'metaKeyword': { message = required.metaKeyword; } break;
          case 'pageTitle': { message = required.pageTitle; } break;
          case 'currency': { message = required.currency; } break;
          case 'dateFormat': { message = required.dateFormat; } break;
          case 'templateName': { message = required.templateName; } break;
          case 'countryName': { message = required.countryName; } break;
          case 'countryId': { message = required.countryId; } break;
          case 'countryCode': { message = required.countryCode; } break;
          case 'companyName': { message = required.companyName; } break;
          case 'phoneCode': { message = required.phoneCode; } break;
          case 'mainCurrency': { message = required.mainCurrency; } break;
          case 'timezone': { message = required.timezone; } break;
          case 'dob': { message = required.dob; } break;
          case 'country': { message = required.country; } break;
          case 'languages': { message = required.languages; } break;

          /*Maintenance */
          case 'emailTitle': { message = required.emailTitle; } break;
          case 'email_message': { message = required.email_message; } break;
          case 'mobileTitle': { message = required.mobileTitle; } break;
          case 'mobile_message': { message = required.mobile_message; } break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'email': { message = pattern.Email; } break;
          case 'Email': { message = pattern.Email; } break;
          case 'password': { message = pattern.Password; } break;
          case 'newPassword': { message = pattern.newPassword; } break;
          case 'confirmPassword': { message = pattern.confirmPassword; } break;
          case 'adminEmail': { message = pattern.adminEmail; } break;
          case 'fromEmailId': { message = pattern.fromEmailId; } break;
          case 'defaultFromEmail': { message = pattern.Email; } break;
          case 'defaultAdminEmail': { message = pattern.Email; } break;
          case 'link': { message = pattern.link; } break;
          case 'latitude': { message = pattern.latitude; } break;
          case 'longitude': { message = pattern.longitude; } break;
          case 'website': { message = pattern.website; } break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'contact_no': { message = minlength.contact_no; } break;
          case 'code': { message = minlength.code; } break;
          case 'answer': { message = minlength.answer; } break;
          case 'question': { message = minlength.question; } break;
          case 'description': { message = minlength.description; } break;
          case 'mobile': { message = minlength.PhoneNo; } break;
          case 'confirmPassword': { message = minlength.confirmPassword; } break;
          case 'newPassword': { message = minlength.newPassword; } break;
          case 'port': { message = minlength.port; } break;

        }
      }
      return message;
    }
  }
  /*****************************************************************************************/
}
