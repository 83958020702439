export const environment = {
  production: false,
  environmentName: 'dev',
  port: 4050,
  recaptcha: {
    siteKey: '6LfCEickAAAAAFVEILreANr4tsHb2Fo8PK6kATgr',
    secrectKey: '6LfCEickAAAAAIaKe0PgZdJJgNmoNj15C49NOZ6A',
  },
  localStorageEncryptionSecret: '!@#$&skillsbase&@dev!',
  apiUrl: 'https://smartawards-api.devpress.net/v1/admin/',
};