import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { SafePipe, SearchFilter, SortableColumnComponent, SortableTableDirective, SortPipe } from './bn-datatable.component';
import { SortService } from './sortService';

@NgModule({
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    SortableModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    NgSelectModule,
  ],
  declarations: [
    SortableColumnComponent,
    SortableTableDirective,
    SortPipe,
    SearchFilter,
    SafePipe,
  ],
  providers: [
    SortService,
  ],
  exports: [
    SortableColumnComponent,
    SortableTableDirective,
    SortPipe,
    SearchFilter,
    CommonModule,
    PaginationModule,
    BsDropdownModule,
    CollapseModule,
    ModalModule,
    SortableModule,
    NgSelectModule,
    SafePipe,
  ],
})
export class BnDatatableModule { }
