import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'limitText'
})
export class LimitTextPipe implements PipeTransform {
  transform(string: any, length: any): any {
    if (string && string.length > length) {
      return string.substr(0, length) + '...';
    }
    return string;
  }
}

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }
  transform(value: any, format?: string): any {
    if (!value) {
      return '';
    }
    format = format || 'short';
    return value && typeof (value) === 'object' ? this.datePipe.transform(value, format) : '';
  }
}

@Pipe({ name: 'htmlToText' })
export class HtmlToTextPipe implements PipeTransform {
  transform(value: any): string {
    const temp = document.createElement('p');
    temp.innerHTML = value;
    return temp.textContent || temp.innerText || '';
  }
}

@Pipe({
  name: 'negToPos'
})
export class NegToPosPipe implements PipeTransform {
  transform(val: number): number {
    return Math.abs(val);
  }
}
