import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { URLConstants } from '../../common/constants/routerLink-constants'
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/service/common.service';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styles: [
  ]
})
export class MaintenanceComponent {
  callAPIConstants = callAPIConstants;
    constructor(public router: Router, public commonService: CommonService) {}
    ngOnInit(){
    }
    tryAgain(){
      this.commonService.callApi(this.callAPIConstants.GetDashboardDetails, '', 'get', false, false, false)
      .then((success) => {
        if (!success.errors) {
          this.router.navigate([URLConstants.DASHBOARD]);
        }
      });
    }

}
